'use client';
import { default as LinkNext, LinkProps } from 'next/link';
import React from 'react';

import useClientTranslation from '@/components/hooks/useClientTranslation';

import { useGameModalStore } from '@/store/zustand';

const Link: React.FC<
  LinkProps & {
    children?: React.ReactNode;
    className?: string;
  } & React.AnchorHTMLAttributes<HTMLAnchorElement> &
    React.RefAttributes<HTMLAnchorElement>
> = ({ children, href, className = '', ...props }) => {
  const { currentLink } = useClientTranslation('');
  return (
    <LinkNext
      onClick={() => {
        if (useGameModalStore.getState().gameInfo?.src) {
          if (useGameModalStore.getState().isLargeSize) {
            useGameModalStore.getState().setLargeSize(false);
          }
        }
      }}
      {...props}
      href={currentLink(href)}
      className={className}
    >
      {children}
    </LinkNext>
  );
};

export default Link;

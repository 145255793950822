import {
  useParams,
  usePathname,
  useRouter,
  useSearchParams,
} from 'next/navigation';

const useChangeLanguage = (lngCurrent = '') => {
  const pathname = usePathname();
  const router = useRouter();
  const searchParams = useSearchParams();
  const params = useParams();
  const { lng } = params || {};

  const handleChangeLanguage = () => {
    const isEngLng = lngCurrent === 'en' || lng === 'en';
    setTimeout(() => {
      const pathnameWithSearchParams = searchParams.toString()
        ? `${pathname}?${searchParams.toString()}`
        : pathname;

      if (!isEngLng && !pathname.includes('/en'))
        router.replace('/en' + pathnameWithSearchParams);
      if (isEngLng && pathnameWithSearchParams.includes('/en')) {
        router.replace(pathnameWithSearchParams.replace('/en', '') || '/');
      }
    }, 380);
  };

  return handleChangeLanguage;
};

export default useChangeLanguage;

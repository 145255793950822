'use client';
import { Divider, Dropdown, Layout, Menu, Skeleton } from 'antd';
import SkeletonAvatar from 'antd/es/skeleton/Avatar';
import clsx from 'clsx';
import Image from 'next/image';
import { useParams } from 'next/navigation';
import React, { useEffect } from 'react';
import useSWR from 'swr';

import { profile } from '@/lib/apis';
import { formatNumber } from '@/lib/helper';

import ButtonCore from '@/components/core/Button';
import Link from '@/components/core/Link';
import TruncatedText from '@/components/core/TruncatedText';
import useClientTranslation from '@/components/hooks/useClientTranslation';
import useRenderNotiUser from '@/components/hooks/useRenderNotiUser';
import GameMenuHeader from '@/components/modules/Layouts/DesktopLayout/Header/components/GameMenuHeader';
import GameSelectSearch from '@/components/modules/Layouts/DesktopLayout/Header/components/GameSelectSearch';

import { useGameModalStore } from '@/store/zustand';

import { lobbyCateProvider } from '@/constant/data';

import SwitchLanguage from './components/SwitchLanguage';
const { Header } = Layout;

const headerStyle: React.CSSProperties = {
  padding: '0',
  height: 'auto',
  position: 'sticky',
  top: 0,
  zIndex: 999,
  width: '100%',
};

const DesktopHeader: React.FC<any> = () => {
  const params = useParams();
  const { slug, id = [] } = params || {};
  const [_, display] = (id || []) as any;
  const { t, lng } = useClientTranslation('HomePage');
  const cateGameSlug = lobbyCateProvider?.[display]?.slug || slug;
  const { gameInfo } = useGameModalStore();
  const isOpenModalGame = !!gameInfo?.src;
  const { isLoading, data } = useSWR('/profile', () => profile(), {
    refreshInterval: gameInfo?.src ? 20000 : 0,
  });
  const userProfile = data;
  const { renderIcon, renderNotification, hasNotiUnRead, handleReadAll } =
    useRenderNotiUser() || {};

  useEffect(() => {
    const mainElm = document.getElementById('main');
    if (!mainElm) return;
    if (!isOpenModalGame) mainElm.classList.add('overflow-x-hidden');
    else mainElm.classList.remove('overflow-x-hidden');
  }, [isOpenModalGame]);

  const renderUserProfile = () => {
    if (userProfile) {
      return (
        <>
          <Dropdown
            className='ml-auto mr-3 flex items-center'
            dropdownRender={renderNavMenu}
            trigger={['hover']}
            placement='bottomRight'
          >
            <div>
              <div className='mr-2 flex flex-col  items-end'>
                <div className='-mb-11'>
                  <div className='font-bold opacity-[88%]'>
                    <TruncatedText
                      text={userProfile?.username || ''}
                      truncateOptions={[
                        { screenSize: 0, truncate: 10 },
                        { screenSize: 1048, truncate: 15 },
                        { screenSize: 1300, truncate: 20 },
                        { screenSize: 1440, truncate: 20 },
                      ]}
                    />
                  </div>
                </div>

                <div className='flex items-center'>
                  <span className='!text-neutral-4 mr-[2px]'>
                    {t('headerMobile.userInfo.balance')}:{' '}
                  </span>

                  <div className='text-main-pri font-bold'>
                    {`${formatNumber(
                      userProfile?.balance && userProfile?.balance >= 0
                        ? userProfile?.balance
                        : 0 || 0
                    )} ฿`}
                  </div>
                </div>
              </div>
              <Link href='/account/info'>
                <div className='bg-main-bg overflow-hidden rounded-full border-2'>
                  <img
                    className='h-12 w-12'
                    src={userProfile?.avatar || '/img/header/avatar.png'}
                    alt=''
                  />
                </div>
              </Link>
            </div>
          </Dropdown>
          <Link href='/account/deposit'>
            <ButtonCore
              size='large'
              className='btn-11 text-14 !bg-linear-1 hover:!shadow-10 mx-3 flex !h-12 !w-[144px] items-center whitespace-pre !rounded-[32px] px-5 !font-bold !text-black hover:!opacity-[0.9]'
            >
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='20'
                height='20'
                viewBox='0 0 20 20'
                fill='none'
              >
                <path
                  fillRule='evenodd'
                  clipRule='evenodd'
                  d='M18 4.17071V3C18 1.34315 16.6569 0 15 0H3C1.34315 0 0 1.34315 0 3V17C0 18.6569 1.34315 20 3 20H17C18.6569 20 20 18.6569 20 17V7C20 5.69378 19.1652 4.58254 18 4.17071ZM2 3C2 2.44772 2.44772 2 3 2H15C15.5523 2 16 2.44772 16 3V4H3C2.44772 4 2 3.55228 2 3ZM2 5.82929C2.31278 5.93985 2.64936 6 3 6H17C17.5523 6 18 6.44771 18 7V9H15C13.3431 9 12 10.3431 12 12C12 13.6569 13.3431 15 15 15H18V17C18 17.5523 17.5523 18 17 18H3C2.44772 18 2 17.5523 2 17V5.82929ZM15 11H18V13H15C14.4477 13 14 12.5523 14 12C14 11.4477 14.4477 11 15 11Z'
                  fill='black'
                  fillOpacity='0.45'
                />
              </svg>
              <span className='ml-2'>
                {' '}
                {t('headerMobile.userInfo.deposit')}
              </span>
            </ButtonCore>
          </Link>
        </>
      );
    }
    return (
      <ButtonCore
        size='large'
        className='text-15 !bg-linear-1 hover:!shadow-10 btn-11 mx-3 flex !h-12 items-center whitespace-pre !rounded-[32px] px-5 !font-bold !text-black hover:!opacity-[0.9]'
        onClick={() => {
          window.popup?.login();
        }}
      >
        {t('header.loginSignUpBtn')}
      </ButtonCore>
    );
  };

  const renderNavMenu = () => {
    return (
      <Menu
        className='menuMobile cursor-hand !-top-3 !right-[-50px] !max-w-[200px] !rounded-lg'
        defaultSelectedKeys={['1']}
        defaultOpenKeys={['sub1']}
        mode='inline'
        selectedKeys={[lng === 'th' ? 'thai' : 'eng']}
      >
        {userProfile && (
          <>
            <Menu.Item
              className='cursor-hand shrink-0'
              key='profile'
              icon={
                <svg
                  className='cursor-hand'
                  xmlns='http://www.w3.org/2000/svg'
                  width={17}
                  height={16}
                  viewBox='0 0 17 16'
                  fill='none'
                >
                  <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M8.28273 6.66658C9.3873 6.66658 10.2827 5.77115 10.2827 4.66658C10.2827 3.56202 9.3873 2.66659 8.28273 2.66659C7.17817 2.66659 6.28274 3.56202 6.28274 4.66658C6.28274 5.77115 7.17817 6.66658 8.28273 6.66658ZM8.28273 7.99992C10.1237 7.99992 11.6161 6.50753 11.6161 4.66658C11.6161 2.82564 10.1237 1.33325 8.28273 1.33325C6.44179 1.33325 4.9494 2.82564 4.9494 4.66658C4.9494 6.50753 6.44179 7.99992 8.28273 7.99992Z'
                    fill='#845D00'
                  />
                  <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M6.28271 10.6666C4.80996 10.6666 3.61605 11.8605 3.61605 13.3333V13.9999C3.61605 14.3681 3.31757 14.6666 2.94938 14.6666C2.58119 14.6666 2.28271 14.3681 2.28271 13.9999V13.3333C2.28271 11.1241 4.07358 9.33325 6.28271 9.33325H10.2827C12.4919 9.33325 14.2827 11.1241 14.2827 13.3333V13.9999C14.2827 14.3681 13.9842 14.6666 13.616 14.6666C13.2479 14.6666 12.9494 14.3681 12.9494 13.9999V13.3333C12.9494 11.8605 11.7555 10.6666 10.2827 10.6666H6.28271Z'
                    fill='#845D00'
                  />
                </svg>
              }
            >
              <Link href='/account/info'>
                {t('headerMobile.userInfo.navMenu1')}
              </Link>
            </Menu.Item>
            <Menu.Item
              className='cursor-hand shrink-0'
              key='transactionHistory'
              icon={
                <svg
                  className='cursor-hand'
                  xmlns='http://www.w3.org/2000/svg'
                  width='17'
                  height='16'
                  viewBox='0 0 17 16'
                  fill='none'
                >
                  <path
                    d='M11.0875 1.86201C10.8271 1.60166 10.405 1.60166 10.1446 1.86201C9.8843 2.12236 9.88429 2.54447 10.1446 2.80482L10.6707 3.33085H6.94938C4.37205 3.33085 2.28271 5.42019 2.28271 7.99752V8.66418C2.28271 9.03237 2.58119 9.33085 2.94938 9.33085C3.31757 9.33085 3.61605 9.03237 3.61605 8.66418V7.99752C3.61605 6.15657 5.10843 4.66418 6.94938 4.66418H10.6738L10.1446 5.19336C9.88429 5.45372 9.8843 5.87583 10.1446 6.13617C10.405 6.39652 10.8271 6.39652 11.0875 6.13616L12.5174 4.70618C12.9079 4.31566 12.9079 3.6825 12.5174 3.29198L11.0875 1.86201Z'
                    fill='#845D00'
                  />
                  <path
                    d='M9.615 12.6667C12.1923 12.6667 14.2817 10.5774 14.2817 8.00008V7.33341C14.2817 6.96522 13.9832 6.66675 13.615 6.66675C13.2468 6.66675 12.9483 6.96522 12.9483 7.33341V8.00008C12.9483 9.84103 11.456 11.3334 9.615 11.3334H5.89054L6.41975 10.8042C6.68009 10.5438 6.68009 10.1217 6.41974 9.86139C6.15939 9.60104 5.73728 9.60104 5.47693 9.86139L4.04698 11.2914C3.65646 11.6819 3.65646 12.3151 4.04698 12.7056L5.47693 14.1355C5.73728 14.3959 6.15939 14.3959 6.41974 14.1356C6.68009 13.8752 6.68009 13.4531 6.41974 13.1927L5.89376 12.6667H9.615Z'
                    fill='#845D00'
                  />
                </svg>
              }
            >
              <Link href='/account/history-payment'>
                {t('headerMobile.userInfo.navMenu2')}
              </Link>
            </Menu.Item>
            <Menu.Item
              className='cursor-hand shrink-0'
              key='bettingHistory'
              icon={
                <svg
                  className='cursor-hand'
                  xmlns='http://www.w3.org/2000/svg'
                  width={17}
                  height={16}
                  viewBox='0 0 17 16'
                  fill='none'
                >
                  <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M12.9494 7.33333H6.28271C5.91453 7.33333 5.61605 7.63181 5.61605 8V10.6667C5.61605 11.0349 5.91453 11.3333 6.28271 11.3333H12.9494C13.3176 11.3333 13.616 11.0349 13.616 10.6667V8C13.616 7.63181 13.3176 7.33333 12.9494 7.33333ZM6.28271 6C5.17815 6 4.28271 6.89543 4.28271 8V10.6667C4.28271 11.7712 5.17815 12.6667 6.28271 12.6667H12.9494C14.054 12.6667 14.9494 11.7712 14.9494 10.6667V8C14.9494 6.89543 14.054 6 12.9494 6H6.28271Z'
                    fill='#845D00'
                  />
                  <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M3.61603 4.66659H10.2827C10.6509 4.66659 10.9494 4.96506 10.9494 5.33325V5.99992H12.2827V5.33325C12.2827 4.22868 11.3873 3.33325 10.2827 3.33325H3.61603C2.51146 3.33325 1.61603 4.22868 1.61603 5.33325V7.99992C1.61603 9.10449 2.51146 9.99992 3.61603 9.99992H4.28269V8.66659H3.61603C3.24784 8.66659 2.94936 8.36811 2.94936 7.99992V5.33325C2.94936 4.96506 3.24784 4.66659 3.61603 4.66659Z'
                    fill='#845D00'
                  />
                  <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M9.61605 10.6667C10.3524 10.6667 10.9494 10.0697 10.9494 9.33333C10.9494 8.59695 10.3524 8 9.61605 8C8.87967 8 8.28271 8.59695 8.28271 9.33333C8.28271 10.0697 8.87967 10.6667 9.61605 10.6667Z'
                    fill='#845D00'
                  />
                  <path
                    d='M7.61605 9.33341C7.61605 9.7016 7.31757 10.0001 6.94938 10.0001C6.58119 10.0001 6.28271 9.7016 6.28271 9.33341C6.28271 8.96522 6.58119 8.66675 6.94938 8.66675C7.31757 8.66675 7.61605 8.96522 7.61605 9.33341Z'
                    fill='#845D00'
                  />
                  <path
                    d='M12.9494 9.33341C12.9494 9.7016 12.6509 10.0001 12.2827 10.0001C11.9145 10.0001 11.616 9.7016 11.616 9.33341C11.616 8.96522 11.9145 8.66675 12.2827 8.66675C12.6509 8.66675 12.9494 8.96522 12.9494 9.33341Z'
                    fill='#845D00'
                  />
                </svg>
              }
            >
              <Link href='/account/history-bet'>
                {t('headerMobile.userInfo.navMenu3')}
              </Link>
            </Menu.Item>
            <Divider className='!m-0' />

            <Menu.Item
              className='cursor-hand shrink-0'
              key='bonusManagement'
              icon={
                <svg
                  className='cursor-hand'
                  xmlns='http://www.w3.org/2000/svg'
                  width={17}
                  height={16}
                  viewBox='0 0 17 16'
                  fill='none'
                >
                  <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M8.28269 2.99992L7.49011 2.00919C7.14833 1.58196 6.63086 1.33325 6.08374 1.33325H5.94936C5.02889 1.33325 4.28269 2.07944 4.28269 2.99992C4.28269 3.11407 4.29417 3.22555 4.31603 3.33325H3.61603C2.51146 3.33325 1.61603 4.22868 1.61603 5.33325V5.99992C1.61603 6.59225 1.87353 7.12444 2.28269 7.49066V12.6666C2.28269 13.7712 3.17813 14.6666 4.28269 14.6666H12.2827C13.3873 14.6666 14.2827 13.7712 14.2827 12.6666V7.49066C14.6919 7.12444 14.9494 6.59225 14.9494 5.99992V5.33325C14.9494 4.22868 14.0539 3.33325 12.9494 3.33325H12.2494C12.2712 3.22555 12.2827 3.11407 12.2827 2.99992C12.2827 2.07944 11.5365 1.33325 10.616 1.33325H10.4817C9.93453 1.33325 9.41706 1.58196 9.07528 2.00919L8.28269 2.99992ZM9.72353 3.33325H10.616C10.8001 3.33325 10.9494 3.18401 10.9494 2.99992C10.9494 2.81582 10.8001 2.66659 10.616 2.66659H10.4817C10.3396 2.66659 10.2052 2.73117 10.1164 2.84212L9.72353 3.33325ZM3.61603 4.66659C3.24784 4.66659 2.94936 4.96506 2.94936 5.33325V5.99992C2.94936 6.36811 3.24784 6.66659 3.61603 6.66659H7.61603V4.66659H3.61603ZM8.94936 4.66659V6.66659H12.9494C13.3176 6.66659 13.616 6.36811 13.616 5.99992V5.33325C13.616 4.96506 13.3176 4.66659 12.9494 4.66659H8.94936ZM5.61603 2.99992C5.61603 3.18401 5.76527 3.33325 5.94936 3.33325H6.84186L6.44896 2.84212C6.3602 2.73117 6.22582 2.66659 6.08374 2.66659H5.94936C5.76527 2.66659 5.61603 2.81582 5.61603 2.99992ZM3.61603 7.99992L3.61603 12.6666C3.61603 13.0348 3.9145 13.3333 4.28269 13.3333H7.61603V7.99992H3.61603ZM8.94936 13.3333V7.99992H12.9494V12.6666C12.9494 13.0348 12.6509 13.3333 12.2827 13.3333H8.94936Z'
                    fill='#845D00'
                  />
                </svg>
              }
            >
              <Link href='/account/promotion'>
                {t('headerMobile.userInfo.navMenu4')}
              </Link>
            </Menu.Item>
            {/* <Menu.Item
              className='cursor-hand shrink-0'
              key='inviteToEarn'
              icon={
                <svg
                  className='cursor-hand'
                  xmlns='http://www.w3.org/2000/svg'
                  width={17}
                  height={16}
                  viewBox='0 0 17 16'
                  fill='none'
                >
                  <path
                    d='M9.94916 7.31263C11.2647 7.14869 12.2827 6.02651 12.2827 4.66657C12.2827 3.30662 11.2647 2.18445 9.94916 2.02051C10.5716 2.72567 10.9494 3.65201 10.9494 4.66657C10.9494 5.68113 10.5716 6.60747 9.94916 7.31263Z'
                    fill='#845D00'
                  />
                  <path
                    d='M13.6161 13.3333C13.6161 13.7015 13.9146 14 14.2827 14C14.6509 14 14.9494 13.7015 14.9494 13.3333V12C14.9494 10.2266 13.5645 8.77663 11.8173 8.67261C12.5187 9.29378 13.0553 10.0969 13.3531 11.008C13.5204 11.3003 13.6161 11.639 13.6161 12V13.3333Z'
                    fill='#845D00'
                  />
                  <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M4.94936 10.0001C3.84479 10.0001 2.94936 10.8955 2.94936 12.0001V13.3334C2.94936 13.7016 2.65088 14.0001 2.28269 14.0001C1.9145 14.0001 1.61603 13.7016 1.61603 13.3334V12.0001C1.61603 10.1591 3.10841 8.66675 4.94936 8.66675H8.94936C10.7903 8.66675 12.2827 10.1591 12.2827 12.0001V13.3334C12.2827 13.7016 11.9842 14.0001 11.616 14.0001C11.2478 14.0001 10.9494 13.7016 10.9494 13.3334V12.0001C10.9494 10.8955 10.0539 10.0001 8.94936 10.0001H4.94936Z'
                    fill='#845D00'
                  />
                  <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M6.94938 3.33333C6.213 3.33333 5.61605 3.93029 5.61605 4.66667C5.61605 5.40305 6.213 6 6.94938 6C7.68576 6 8.28271 5.40305 8.28271 4.66667C8.28271 3.93029 7.68576 3.33333 6.94938 3.33333ZM4.28271 4.66667C4.28271 3.19391 5.47662 2 6.94938 2C8.42214 2 9.61605 3.19391 9.61605 4.66667C9.61605 6.13943 8.42214 7.33333 6.94938 7.33333C5.47662 7.33333 4.28271 6.13943 4.28271 4.66667Z'
                    fill='#845D00'
                  />
                </svg>
              }
            >
              <Link href='/account/referral'>
                {t('headerMobile.userInfo.navMenu6')}
              </Link>
            </Menu.Item> */}
            <Divider className='!m-0' />
          </>
        )}
        {/* <Menu.Item
          className='cursor-hand'
          key='news'
          icon={
            <svg
              className='cursor-hand'
              xmlns='http://www.w3.org/2000/svg'
              width={17}
              height={16}
              viewBox='0 0 17 16'
              fill='none'
            >
              <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M4.9494 7.33341C4.9494 6.96522 5.24788 6.66675 5.61607 6.66675H10.9494C11.3176 6.66675 11.6161 6.96522 11.6161 7.33341C11.6161 7.7016 11.3176 8.00008 10.9494 8.00008H5.61607C5.24788 8.00008 4.9494 7.7016 4.9494 7.33341Z'
                fill='#845D00'
              />
              <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M4.9494 9.99992C4.9494 9.63173 5.24788 9.33325 5.61607 9.33325H8.28274C8.65093 9.33325 8.9494 9.63173 8.9494 9.99992C8.9494 10.3681 8.65093 10.6666 8.28274 10.6666H5.61607C5.24788 10.6666 4.9494 10.3681 4.9494 9.99992Z'
                fill='#845D00'
              />
              <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M14.2827 12.6666C14.2827 13.7712 13.3873 14.6666 12.2827 14.6666H4.28272C3.17815 14.6666 2.28271 13.7712 2.28271 12.6666V3.33325C2.28271 2.22868 3.17815 1.33325 4.28271 1.33325H10.9494L10.9565 1.34039C11.4251 1.38009 11.8668 1.58398 12.2018 1.91904L13.6969 3.41413C14.032 3.74919 14.2359 4.19088 14.2756 4.65945L14.2827 4.66659V12.6666ZM12.2827 13.3333H4.28272C3.91453 13.3333 3.61605 13.0348 3.61605 12.6666V3.33325C3.61605 2.96506 3.91452 2.66659 4.28271 2.66659H10.2827V3.99992C10.2827 4.7363 10.8797 5.33325 11.616 5.33325H12.9494V12.6666C12.9494 13.0348 12.6509 13.3333 12.2827 13.3333Z'
                fill='#845D00'
              />
            </svg>
          }
        >
          <Link href='/news'>{t('headerMobile.userInfo.navMenu7')}</Link>
        </Menu.Item> */}
        <Menu.Item
          className='cursor-hand'
          key='helpCenter'
          icon={
            <svg
              className='cursor-hand'
              xmlns='http://www.w3.org/2000/svg'
              width={17}
              height={16}
              viewBox='0 0 17 16'
              fill='none'
            >
              <path
                d='M10.9494 3.99998C11.2126 3.99998 11.47 3.92206 11.689 3.77605L13.616 2.49134V8.66665C13.616 9.03484 13.3176 9.33331 12.9494 9.33331H12.2827V10.6666H12.9494C14.054 10.6666 14.9494 9.77122 14.9494 8.66665V2.49134C14.9494 1.42641 13.7625 0.791221 12.8764 1.38194L10.9494 2.66665H6.28271C5.17815 2.66665 4.28271 3.56208 4.28271 4.66665V5.33331H5.61605V4.66665C5.61605 4.29846 5.91453 3.99998 6.28271 3.99998H10.9494Z'
                fill='#845D00'
              />
              <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M4.11643 12.2922C4.35285 12.1031 4.6466 12 4.94936 12H10.2827C10.6509 12 10.9494 11.7016 10.9494 11.3334V7.33337C10.9494 6.96518 10.6509 6.66671 10.2827 6.66671H3.61603C3.24784 6.66671 2.94936 6.96518 2.94936 7.33337V13.2259L4.11643 12.2922ZM4.94936 13.3334H10.2827C11.3873 13.3334 12.2827 12.4379 12.2827 11.3334V7.33337C12.2827 6.2288 11.3873 5.33337 10.2827 5.33337H3.61603C2.51146 5.33337 1.61603 6.2288 1.61603 7.33337V13.2259C1.61603 14.3439 2.90927 14.9654 3.78229 14.267L4.94936 13.3334Z'
                fill='#845D00'
              />
              <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M4.28271 9.33329C4.28271 8.9651 4.58119 8.66663 4.94938 8.66663H8.94938C9.31757 8.66663 9.61605 8.9651 9.61605 9.33329C9.61605 9.70148 9.31757 9.99996 8.94938 9.99996H4.94938C4.58119 9.99996 4.28271 9.70148 4.28271 9.33329Z'
                fill='#845D00'
              />
            </svg>
          }
        >
          {t('headerMobile.userInfo.navMenu9')}
        </Menu.Item>
        {userProfile && (
          <>
            <Divider className='!m-0' />
            <Menu.Item
              className='cursor-hand shrink-0'
              key='Logout'
              onClick={() => window.popup?.logout()}
              icon={
                <svg
                  className='cursor-hand'
                  xmlns='http://www.w3.org/2000/svg'
                  width={20}
                  height={20}
                  viewBox='0 0 28 20'
                  fill='none'
                >
                  <path
                    d='M17.9898 7.79289C17.5993 7.40237 16.9661 7.40237 16.5756 7.79289C16.1851 8.18342 16.1851 8.81658 16.5756 9.20711L18.3685 11H9.28271C8.73043 11 8.28271 11.4477 8.28271 12C8.28271 12.5523 8.73043 13 9.28271 13H18.3685L16.5756 14.7929C16.1851 15.1834 16.1851 15.8166 16.5756 16.2071C16.9661 16.5976 17.5993 16.5976 17.9898 16.2071L20.7827 13.4142C21.5638 12.6332 21.5638 11.3668 20.7827 10.5858L17.9898 7.79289Z'
                    fill='#845D00'
                  />
                  <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M3.28271 5C3.28271 3.34315 4.62586 2 6.28271 2H12.2827C13.9396 2 15.2827 3.34315 15.2827 5V6C15.2827 6.55228 14.835 7 14.2827 7C13.7304 7 13.2827 6.55228 13.2827 6V5C13.2827 4.44772 12.835 4 12.2827 4H6.28271C5.73043 4 5.28271 4.44772 5.28271 5V19C5.28271 19.5523 5.73043 20 6.28271 20H12.2827C12.835 20 13.2827 19.5523 13.2827 19V18C13.2827 17.4477 13.7304 17 14.2827 17C14.835 17 15.2827 17.4477 15.2827 18V19C15.2827 20.6569 13.9396 22 12.2827 22H6.28271C4.62586 22 3.28271 20.6569 3.28271 19V5Z'
                    fill='#845D00'
                  />
                </svg>
              }
            >
              {/* {t('headerMobile.userInfo.navMenu1')} */}
              {t('header.menuItem19')}
            </Menu.Item>
          </>
        )}
      </Menu>
    );
  };

  return (
    <Header
      style={{
        ...headerStyle,
        zIndex: gameInfo.src ? 9999999 : headerStyle.zIndex,
      }}
    >
      <div className='flex h-[80px] items-center bg-gradient-to-r from-[#FFF8DD] to-[#FFF] px-6'>
        <div className='container-pc flex flex-row items-center justify-between'>
          <Link className='h-[48px] min-w-[197px]' href='/'>
            <Image
              priority
              className='hover-cursor h-[48px] w-[197px]'
              alt='logo'
              height={31}
              width={139}
              src='/img/logo/main-logo.svg'
            />
          </Link>
          <GameSelectSearch />

          <div className='flex shrink-0 flex-row items-center'>
            {isLoading ? (
              <>
                <div className='ml-auto mt-8 flex h-[64px]'>
                  <div className='mr-3'>
                    <Skeleton
                      className='end mb-1 ml-auto flex justify-end text-right'
                      active
                      round
                      title={false}
                      paragraph={{ rows: 1, width: 50 }}
                    />
                    <Skeleton
                      className='end ml-auto mt-1 flex justify-end text-right'
                      active
                      round
                      title={false}
                      paragraph={{ rows: 1, width: 50 }}
                    />
                  </div>
                  <SkeletonAvatar className='ml-auto mr-2 h-40 w-40' active />
                </div>
              </>
            ) : (
              renderUserProfile()
            )}
            <Dropdown
              className={clsx(
                'ml-auto flex items-center',
                hasNotiUnRead && 'mr-3'
              )}
              dropdownRender={() => {
                return (
                  <div className='w-[375px] rounded-lg bg-[#fcfcfc] py-4 pb-4'>
                    <ButtonCore
                      onClick={handleReadAll}
                      className='!bg-neutral-1 mx-auto !h-[45px] !w-[94%] !rounded-3xl !text-black'
                    >
                      <svg
                        className='h-4 w-4 '
                        xmlns='http://www.w3.org/2000/svg'
                        width='16'
                        height='16'
                        viewBox='0 0 24 24'
                        fill='none'
                      >
                        <path
                          fill-rule='evenodd'
                          clip-rule='evenodd'
                          d='M2 16.5959C2 16.2151 2.15471 15.8506 2.42864 15.586L3.45759 14.5922C3.84928 14.2139 4.06977 13.6922 4.06814 13.1476L4.05867 9.9946C4.04543 5.58319 7.61789 2 12.0293 2C16.4314 2 20 5.56859 20 9.97067L20 13.1716C20 13.702 20.2107 14.2107 20.5858 14.5858L21.5858 15.5858C21.851 15.851 22 16.2107 22 16.5858C22 17.3668 21.3668 18 20.5858 18H16C16 20.2091 14.2091 22 12 22C9.79086 22 8 20.2091 8 18H3.40408C2.62863 18 2 17.3714 2 16.5959ZM10 18C10 19.1046 10.8954 20 12 20C13.1046 20 14 19.1046 14 18H10ZM18 13.1716C18 14.2324 18.4214 15.2499 19.1716 16L4.87851 16C5.64222 15.246 6.07136 14.2161 6.06813 13.1416L6.05867 9.9886C6.04875 6.6841 8.7248 4 12.0293 4C15.3268 4 18 6.67316 18 9.97067L18 13.1716Z'
                          fill='#000000'
                        />
                      </svg>
                      <span className='text-14 !mx-2 font-bold'>
                        {' '}
                        Notification
                      </span>
                      {hasNotiUnRead && (
                        <div className='bg-error-base border-1 h-2 w-2 rounded-full border border-white' />
                      )}
                    </ButtonCore>
                    <div className='mt-3 max-h-[650px] overflow-y-scroll px-3'>
                      {renderNotification?.()}
                    </div>
                  </div>
                );
              }}
              trigger={['click']}
              placement='bottom'
            >
              <span className='cursor-hand'>{renderIcon?.()}</span>
            </Dropdown>

            <SwitchLanguage />
          </div>
        </div>
      </div>

      <GameMenuHeader id={cateGameSlug} t={t} />
    </Header>
  );
};

export default React.memo(DesktopHeader);

/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable react-hooks/exhaustive-deps */
'use client';
import { LoadingOutlined } from '@ant-design/icons';
import { AutoComplete, Empty, Spin } from 'antd';
import clsx from 'clsx';
import fuzzy from 'fuzzy';
import debounce from 'lodash.debounce';
import React, { useEffect, useMemo, useState } from 'react';
import useSWR from 'swr';

import { getGames } from '@/lib/apis';
import { removeAccent } from '@/lib/helper';
import { getRandomInt, isThaiLang, mapLabelFilterProvider } from '@/lib/utils';

import Link from '@/components/core/Link';
import { openGameProvider } from '@/components/core/OpenGame';
import useClientTranslation from '@/components/hooks/useClientTranslation';
import useIsMobile from '@/components/hooks/useScreenSize';

import { hotItems, LobbyCate, lobbyCateProvider } from '@/constant/data';
import { gameMenuDesktop } from '@/constant/gameMenu';

const availableProvider = Object.values(lobbyCateProvider)
  .map((_: any) => {
    return [..._.partner_provider];
  })
  ?.flat();

// @ts-ignore
const partners = [...new Set(availableProvider)];

const randomHotGame = getRandomInt(5, 11);
const providerAvailable = gameMenuDesktop
  .map((_) => _.games)
  .flat()
  .map((_) =>
    _.lobbyCate && _.lobbyCate !== 'hot'
      ? {
          ..._,
        }
      : ''
  )
  .filter(Boolean)
  .map((_) => {
    return {
      partner_provider: _.partner_provider,
      lobbyCate: LobbyCate[_.lobbyCate]?.label,
      label: (
        <Link
          href={`/game/provider/${_.partner_provider}/${_.lobbyCate}`}
          className='flex w-full items-center hover:!text-black'
        >
          <div className='bg-main-secondary mr-4 flex h-12 w-12 items-center justify-center rounded-lg'>
            <img
              className='h-[28px] object-contain'
              src={`/img/game/banner/logo-provider/${_.partner_provider}.png`}
              alt=''
            />
          </div>
          <div className='mr-auto'>
            <div className='text-15 font-semibold capitalize'>
              {mapLabelFilterProvider(_.partner_provider)}
            </div>
            <div className='text-neutral-4'>
              {LobbyCate[_.lobbyCate]?.label}
            </div>
          </div>
          <div>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='24'
              height='24'
              viewBox='0 0 24 24'
              fill='none'
            >
              <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M8.04289 19.2071C7.65237 18.8166 7.65237 18.1834 8.04289 17.7929L13.8358 12L8.04289 6.20711C7.65237 5.81658 7.65237 5.18342 8.04289 4.79289C8.43342 4.40237 9.06658 4.40237 9.45711 4.79289L15.25 10.5858C16.031 11.3668 16.031 12.6332 15.25 13.4142L9.45711 19.2071C9.06658 19.5976 8.43342 19.5976 8.04289 19.2071Z'
                fill='#9A9FA5'
              />
            </svg>
          </div>
        </Link>
      ),
    };
  });

const initHotGameOptions = hotItems
  .slice(randomHotGame, randomHotGame + 3)
  .map((_) => {
    return {
      display_types: _.display_types,
      label: (
        <div
          key={_.id}
          className='cursor-hand flex items-center'
          onClick={() => {
            openGameProvider(_);
          }}
        >
          <div className='flex w-full items-center'>
            <div
              className='bg-linear-gray-2 mr-4 flex h-12 w-12 items-center justify-center rounded-xl'
              style={{
                boxShadow: 'rgba(0, 0, 0, 0.05) 2px 2px 0px 0px',
              }}
            >
              {_?.image ? (
                <img
                  className='h-12 w-16 shrink-0 rounded-lg border object-cover'
                  alt=''
                  src={_?.image}
                />
              ) : (
                <div />
              )}
            </div>
            <div className='mr-auto'>
              <div className='text-neutral-4'>
                {_?.display_types.map((_: any) => LobbyCate[_]?.label).join('')}
              </div>
              <div className='text-15 font-semibold capitalize'>
                {mapLabelFilterProvider(_.name)}
              </div>
            </div>
            <div>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='24'
                height='24'
                viewBox='0 0 24 24'
                fill='none'
              >
                <path
                  fillRule='evenodd'
                  clipRule='evenodd'
                  d='M8.04289 19.2071C7.65237 18.8166 7.65237 18.1834 8.04289 17.7929L13.8358 12L8.04289 6.20711C7.65237 5.81658 7.65237 5.18342 8.04289 4.79289C8.43342 4.40237 9.06658 4.40237 9.45711 4.79289L15.25 10.5858C16.031 11.3668 16.031 12.6332 15.25 13.4142L9.45711 19.2071C9.06658 19.5976 8.43342 19.5976 8.04289 19.2071Z'
                  fill='#9A9FA5'
                />
              </svg>
            </div>
          </div>
        </div>
      ),
    };
  });

const depositKeyWords = [
  // DEPOSIT
  {
    path: '/account/deposit',
    label: 'deposit_money',
    description: 'banking',
    searchParams: '?banking=',
    isAppendResult: true,
    keyword: [
      'aeepay',
      'bigpayz',
      'qr code',
      'banking_transfer',
      'transfer_banking',
      'banking_deposit',
      'deposit_banking',
      'เติมเงิน',

      'nap_tien_aeepay',
      'deposit_money_aeepay',
      'เติมเงิน_aeepay',

      'nap_tien_aeepay_banking_transfer',
      'deposit_money_aeepay_banking_transfer',
      'เติมเงิน_aeepay_banking_transfer',

      'nap_tien_bigpayz',
      'deposit_money_bigpayz',
      'เติมเงิน_bigpayz',

      'nap_tien_qr_code',
      'deposit_money_qr_code',
      'เติมเงิน_qr_code',

      'nap_tien_banking_transfer',
      'deposit_money_banking_transfer',
      'เติมเงิน_banking_transfer',
    ],
  },
  {
    path: '/account/deposit',
    label: 'deposit_money',
    description: 'crypto',
    searchParams: '?crypto=on',
    keyword: [
      'โหลดแล้ว',
      'เงิน',
      'ฝากเงิน',
      'เครื่องมือคริปโต',
      'wallet',
      'token',
    ],
  },
  {
    path: '/account/deposit',
    label: 'deposit_money',
    description: 'crypto',
    searchParams: '?crypto=',
    isAppendResult: true,
    keyword: [
      'nap_tien_eth',
      'crypto_eth',
      'deposit_crypto_eth',
      'crypto_eth_deposit',
      'money_crypto_eth',
      'wallet_eth',
      'crypto_eth',
      'เติมเงิน_eth',
      'เครื่องมือคริปโต_eth',
      'nap_tien_bnb',
      'crypto_bnb',
      'deposit_crypto_bnb',
      'crypto_bnb_deposit',
      'money_crypto_bnb',
      'wallet_bnb',
      'crypto_bnb',
      'เติมเงิน_bnb',
      'เครื่องมือคริปโต_bnb',
      'nap_tien_usdt',
      'deposit_usdt',
      'usdt_deposit',
      'money_usdt',
      'wallet_usdt',
      'เติมเงิน_usdt',
      'เครื่องมือคริปโต_usdt',
      'nap_tien_trc20',
      'crypto_trc20',
      'deposit_crypto_trc20',
      'crypto_trc20_deposit',
      'money_crypto_trc20',
      'wallet_trc20',
      'crypto_trc20',
      'เติมเงิน_trc20',
      'เครื่องมือคริปโต_trc20',
      'nap_tien_bep20',
      'crypto_bep20',
      'deposit_crypto_bep20',
      'crypto_bep20_deposit',
      'money_crypto_bep20',
      'wallet_bep20',
      'crypto_bep20',
      'เติมเงิน_bep20',
      'เครื่องมือคริปโต_bep20',
      'nap_tien_erc20',
      'crypto_erc20',
      'deposit_crypto_erc20',
      'crypto_erc20_deposit',
      'money_crypto_erc20',
      'wallet_erc20',
      'crypto_erc20',
      'เติมเงิน_erc20',
      'เครื่องมือคริปโต_erc20',
    ],
  },
];
const withdrawKeyWords = [
  // WITHDRAW
  {
    path: '/account/withdraw',
    label: 'withdraw_money',
    description: 'banking',
    searchParams: '?banking=',
    isAppendResult: true,
    keyword: [
      'banking_transfer',
      'transfer_banking',
      'banking_withdraw',
      'withdraw_banking',
      'เติมเงิน',

      'เงิน',
      'เบิกเงิน',
      'withdraw_aeepay',
      'เบิกเงิน_aeepay',

      'rut_aeepay_banking_transfer',
      'withdraw_aeepay_banking_transfer',
      'เบิกเงิน_aeepay_banking_transfer',

      'rut_tien_bigpayz',
      'withdraw_money_bigpayz',
      'เบิกเงิน_bigpayz',

      'rut_tien_easypay',
      'withdraw_money_easypay',
      'เบิกเงิน_easypay',

      'rut_tien_qr_code',
      'withdraw_money_qr_code',
      'เบิกเงิน_qr_code',

      'rut_tien_banking_transfer',
      'withdraw_money_banking_transfer',
      'เบิกเงิน_banking_transfer',
    ],
  },
  {
    path: '/account/withdraw',
    label: 'withdraw_money',
    description: 'crypto',
    searchParams: '?crypto=on',
    keyword: ['เงิน', 'ฝากเงิน', 'เครื่องมือคริปโต', 'wallet', 'token'],
  },
  {
    path: '/account/withdraw',
    label: 'withdraw_money',
    description: 'crypto',
    searchParams: '?crypto=',
    isAppendResult: true,
    keyword: [
      'rut_tien_crypto_eth',
      'withdraw_money_eth',
      'withdraw_crypto_eth',
      'wallet_eth',
      'crypto_eth',
      'เบิกเงิน_eth',
      'เครื่องมือคริปโต_eth',
      'rut_tien_crypto_bnb',
      'withdraw_money_bnb',
      'withdraw_crypto_bnb',
      'wallet_bnb',
      'crypto_bnb',
      'เบิกเงิน_bnb',
      'เครื่องมือคริปโต_bnb',
      'rut_tien_crypto_usdt',
      'withdraw_money_usdt',
      'withdraw_crypto_usdt',
      'wallet_usdt',
      'crypto_usdt',
      'เบิกเงิน_usdt',
      'เครื่องมือคริปโต_usdt',
      'rut_tien_crypto_trc20',
      'withdraw_money_trc20',
      'withdraw_crypto_trc20',
      'wallet_trc20',
      'crypto_trc20',
      'เบิกเงิน_trc20',
      'เครื่องมือคริปโต_trc20',
      'rut_tien_crypto_bep20',
      'withdraw_money_bep20',
      'withdraw_crypto_bep20',
      'wallet_bep20',
      'crypto_bep20',
      'เบิกเงิน_bep20',
      'เครื่องมือคริปโต_bep20',
      'rut_tien_crypto_erc20',
      'withdraw_money_erc20',
      'withdraw_crypto_erc20',
      'wallet_erc20',
      'crypto_erc20',
      'เบิกเงิน_erc20',
      'เครื่องมือคริปโต_erc20',
    ],
  },
];

const GameSelectSearch: React.FC<any> = ({ ...props }) => {
  const [searchValue, setSearchValue] = useState('');
  const [isFocus, setIsFocus] = useState(false);

  const { t } = useClientTranslation('HomePage');
  const isMobile = useIsMobile();

  const {
    data: games,
    mutate,
    isLoading,
    isValidating,
  } = useSWR(
    searchValue ? '/get-games' + searchValue : null,
    () => {
      return getGames({
        limit: 10,
        device: isMobile ? 'mobile' : 'pc',
        name: searchValue?.trim(),
        page: 1,
        partner: partners.join(','),
      });
    },
    {
      revalidateOnFocus: false,
    }
  );

  useEffect(() => {
    if (games) mutate();
  }, [searchValue, mutate]);

  const loading = isLoading || isValidating;
  const onSearchGame = useMemo(() => {
    return debounce(setSearchValue, 500);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderLoading = () => {
    return (
      <div className='w-full text-center'>
        <Spin
          size='small'
          tip='Loading...'
          indicator={<LoadingOutlined color='#d4a302' spin />}
        />
      </div>
    );
  };

  const gameOptions = useMemo(
    () =>
      games?.data?.items?.map((item: any) => ({
        display_types: item?.display_types,
        label: (
          <div
            key={item.id}
            className='cursor-hand flex items-center'
            onClick={() => {
              openGameProvider(item);
            }}
          >
            <div className='flex items-center'>
              <div
                className='bg-linear-gray-2 mr-4 flex h-12 w-12 items-center justify-center rounded-xl'
                style={{
                  boxShadow: 'rgba(0, 0, 0, 0.05) 2px 2px 0px 0px',
                }}
              >
                {item?.image ? (
                  <img
                    className='h-12 w-16 shrink-0 rounded-lg border object-cover'
                    alt=''
                    src={item?.image}
                  />
                ) : (
                  <div />
                )}
              </div>
              <div>
                <div className='text-neutral-4'>
                  {item?.display_types
                    .map((_: any) => LobbyCate[_]?.label)
                    .join('')}
                </div>
                <div className='text-15 font-semibold capitalize'>
                  {mapLabelFilterProvider(item.name)}
                </div>
              </div>
            </div>
          </div>
        ),
        // value: item.id,
      })),
    [games?.data?.items]
  );

  const providerOptions = useMemo(() => {
    const dataFilter = fuzzy
      ?.filter(removeAccent(searchValue), providerAvailable, {
        extract: function (el: any) {
          return removeAccent(el.partner_provider) + removeAccent(el.lobbyCate);
        },
      })
      ?.map((_) => {
        return _.original;
      });
    return dataFilter;
  }, [searchValue]);

  const functionOptions = useMemo(() => {
    const options = {
      extract: (el: any) => {
        return isThaiLang(el) ? el : removeAccent(el);
      },
    };

    const dataFilter = [...depositKeyWords, ...withdrawKeyWords].reduce(
      (acc: any, item: any) => {
        const search = isThaiLang(searchValue)
          ? searchValue
          : removeAccent(searchValue);
        const results = fuzzy
          .filter(search, item.keyword, options)
          .map((result) => {
            return result.string;
          });

        if (results.length > 0) {
          let searchParams = item.searchParams || '';

          if (item.searchParams && item.isAppendResult) {
            searchParams = `${item.searchParams}${results?.[0]}`;
          }

          acc.push({
            ...item,
            label: item.label,
            description: item.description,
            searchParams: searchParams,
            // results: results,
          });
        } else {
          // const result = depositKeyWords.filter((_: any) => {
          //   return _.keyword
          //     .filter((k: any) => searchValue.includes(k))
          //     .filter((_: any) => _.isAppendResult);
          // });
        }

        return acc;
      },
      []
    );

    return dataFilter.map((_: any) => {
      return {
        label: (
          <Link
            href={`${_.path}${_.searchParams}`}
            className='flex w-full items-center hover:!text-black'
          >
            <div className='mr-auto'>
              <div className='text-12 text-neutral-4 capitalize'>
                {t(_.label)}
              </div>
              <div className='text-15 font-semibold capitalize'>
                {t(_.description)}
              </div>
            </div>
            <div>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='24'
                height='24'
                viewBox='0 0 24 24'
                fill='none'
              >
                <path
                  fillRule='evenodd'
                  clipRule='evenodd'
                  d='M8.04289 19.2071C7.65237 18.8166 7.65237 18.1834 8.04289 17.7929L13.8358 12L8.04289 6.20711C7.65237 5.81658 7.65237 5.18342 8.04289 4.79289C8.43342 4.40237 9.06658 4.40237 9.45711 4.79289L15.25 10.5858C16.031 11.3668 16.031 12.6332 15.25 13.4142L9.45711 19.2071C9.06658 19.5976 8.43342 19.5976 8.04289 19.2071Z'
                  fill='#9A9FA5'
                />
              </svg>
            </div>
          </Link>
        ),
      };
    });
  }, [searchValue, t]);

  const selectOptions = useMemo(() => {
    const gameOptionsTotal = games?.data?.items?.length || 0;
    const providerOptionsTotal = providerOptions?.length || 0;
    const isInit = searchValue === '';

    const gameOptionsSliced =
      providerOptionsTotal === 0 ? gameOptions : gameOptions?.slice(0, 3);
    const providerOptionsSliced =
      gameOptionsTotal === 0 ? providerOptions : providerOptions?.slice(0, 3);
    const functionOptionsSliced = functionOptions;

    const groupedOptions = isInit
      ? [
          {
            label: (
              <span className='text-main-secondary font-semibold'>
                {t('hot_games')}
              </span>
            ),
            title: t('game'),
            options: initHotGameOptions,
          },
          {
            label: (
              <span className='text-main-secondary font-semibold'>
                {t('hot_providers')}
              </span>
            ),
            title: t('provider'),
            options: providerOptions.slice(randomHotGame, randomHotGame + 3),
          },
          {
            label: (
              <span className='text-main-secondary font-semibold'>
                {t('function')}
              </span>
            ),
            title: t('function'),
            options: functionOptions.slice(0, 1),
          },
        ]
      : [
          gameOptionsSliced?.length > 0 && {
            label: (
              <span className='text-main-secondary font-semibold'>
                {t('game')}
              </span>
            ),
            title: t('game'),
            options: gameOptionsSliced,
          },
          providerOptionsSliced?.length > 0 && {
            label: (
              <span className='text-main-secondary font-semibold'>
                {t('provider')}
              </span>
            ),
            title: t('provider'),
            options: providerOptionsSliced,
          },
          functionOptionsSliced?.length > 0 && {
            label: (
              <span className='text-main-secondary font-semibold'>
                {t('function')}
              </span>
            ),
            title: t('function'),
            options: functionOptionsSliced,
          },
        ].filter(Boolean);

    return loading
      ? [
          {
            label: renderLoading(),
          },
        ]
      : groupedOptions;
  }, [
    games?.data?.items?.length,
    providerOptions,
    searchValue,
    gameOptions,
    functionOptions,
    loading,
  ]);

  return (
    <div
      className={clsx('flex items-center', isFocus && 'transition-all')}
      style={
        isFocus
          ? {
              filter: 'drop-shadow(0px 0px 1px rgba(183, 140, 64,0.3))',
            }
          : {}
      }
    >
      <label></label>
      <AutoComplete
        listHeight='700px'
        listItemHeight='300px'
        onBlur={() => setIsFocus(false)}
        onDropdownVisibleChange={() => {
          setIsFocus(true);
        }}
        dropdownStyle={{
          top: '64px',
        }}
        dropdownClassName='gameSelectDropdown'
        allowClear
        suffixIcon={
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='24'
            height='24'
            viewBox='0 0 24 24'
            fill='none'
          >
            <path
              fillRule='evenodd'
              clipRule='evenodd'
              d='M18.0319 16.6177C19.2635 15.078 20 13.125 20 11C20 6.02944 15.9706 2 11 2C6.02944 2 2 6.02944 2 11C2 15.9706 6.02944 20 11 20C13.125 20 15.078 19.2635 16.6177 18.0319L19.2929 20.7071C19.6834 21.0976 20.3166 21.0976 20.7071 20.7071C21.0976 20.3166 21.0976 19.6834 20.7071 19.2929L18.0319 16.6177ZM18 11C18 14.866 14.866 18 11 18C7.13401 18 4 14.866 4 11C4 7.13401 7.13401 4 11 4C14.866 4 18 7.13401 18 11Z'
              fill='#6F767E'
            />
          </svg>
        }
        showSearch
        className='gameSelectSearch !h-[48px] !w-[365px] !rounded-[26px] pl-6'
        placeholder={t('header.input')}
        filterOption={false}
        onSearch={onSearchGame}
        notFoundContent={
          loading ? (
            renderLoading()
          ) : (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description='No results found'
            ></Empty>
          )
        }
        options={selectOptions}
        {...props}
      />
    </div>
  );
};

export default React.memo(GameSelectSearch);

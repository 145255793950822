/* eslint-disable react-hooks/exhaustive-deps */
import debounce from 'lodash.debounce';
import React, { useLayoutEffect, useState } from 'react';

import { truncate } from '@/lib/utils';

interface TruncatedTextProps {
  text: string;
  truncateOptions: { screenSize: number; truncate: number }[];
}

const TruncatedText: React.FC<TruncatedTextProps> = ({
  text = '',
  truncateOptions,
}) => {
  const [truncateLength, setTruncateLength] = useState<number>(text?.length);

  // Function to determine the truncate length based on screen size
  const updateTruncateLength = () => {
    const screenWidth = window.innerWidth;
    let newTruncateLength = text?.length;

    // Sort truncateOptions by screenSize ascending
    const sortedOptions = truncateOptions.sort(
      (a, b) => a.screenSize - b.screenSize
    );

    // Find the first option where screenSize is greater than or equal to screenWidth
    sortedOptions.forEach((option) => {
      if (screenWidth >= option.screenSize) {
        newTruncateLength = option.truncate;
      }
    });

    setTruncateLength(newTruncateLength);
  };
  const debouncedUpdate = debounce(updateTruncateLength, 500);
  // Effect to update truncate length on screen size change
  useLayoutEffect(() => {
    updateTruncateLength();

    // Event listener for screen size change with debounce
    const handleResize = () => {
      debouncedUpdate();
    };

    window.addEventListener('resize', handleResize);

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [text, truncateOptions]); // Dependencies for the effect

  // Truncate the text based on the current truncate length
  const truncatedText = text?.length > 0 ? truncate(text, truncateLength) : '';

  return <>{truncatedText}</>;
};

export default TruncatedText;

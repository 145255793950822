import { Empty } from 'antd';
import { HttpStatusCode } from 'axios';
import clsx from 'clsx';
import { getCookie } from 'cookies-next';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import useSWR from 'swr';

import { getNotification, putNotification } from '@/lib/apis';

import { useAppRouter } from '@/components/hooks/useAppRouter';
import useClientTranslation from '@/components/hooks/useClientTranslation';

// for transactions
// validStatus = ['FINISHED', 'CANCEL'];
// validActions = ['WITHDRAW', 'DEPOSIT'];

// we have [FINISHED and CANCEL, UPDATED_INFO] status
// in some cases status will be UPDATED_INFO it means that user updated some field in the record without changing the status
// PAYMENT
// COMMISSION
// CASHBACK_WEEK
// WELCOME
// REFERRAL
// BONUS
// PROMOTION
// COMMISSION_WEEK
// PROMOTION_CANCEL [ only have CANCEL status for this ]

// for user KYC events
// NEW_USER;
// VERIFIED_EMAIL;
// VERIFIED_PHONE;
// USER_KYC;

// const notifiData = {
//   status: 'OK',
//   code: 200,
//   data: [
//     {
//       _id: '6679278a473cdf8a64b1f7cc',
//       branch: 'bc119193',
//       content:
//         'Bạn vừa đăng ký tài khoản LuLu88 thành công, vui lòng xác thực số điện thoại để được sử dụng các trò chơi của LuLu88.',
//       created_tim: '1719216010000',
//       data_detail: null,
//       read_status: 'true',
//       status: 'PUBLISH',
//       title: 'Đăng ký tài khoản thành công!',
//       type: 'REGISTER',
//       user_id: '100884',
//     },
//     {
//       _id: '6679286cabdbd68a655fd644',
//       branch: 'bc119193',
//       content:
//         'Bạn đã nạp thành công 500K vào LuLu88 bằng hình thức Chuyển khoản ngân hàng DongA. Vui lòng truy cập vào đây để nhận khuyến mãi.',
//       created_tim: '1719216236083',
//       data_detail: null,
//       read_status: 'false',
//       status: 'CASHBACK',
//       title: 'Nạp tiền thành công!',
//       type: 'CASHBACK',
//       user_id: '100884',
//     },
//   ],
// };

// #no	notification	Notification Type
// 1	Register	REGISTER,
// 2	ID verification	ID_VERIFICATION,
// 3	ID verification sucessful	ID_VERIFICATION,
// 4	ID verification rejected	ID_VERIFICATION,
// 5	Phone verification successful	PHONE_VERIFICATION,
// 6	Line verification successful	LINE_VERIFICATION,
// 7	Deposit rejected	DEPOSIT,
// 8	Deposit successful	DEPOSIT,
// 9	Withdraw successful	WITHDRAW,
// 10	Withdraw rejected!	WITHDRAW,
// 11	Cashback received	CASHBACK,
// 12	100% bonus	BONUS,
// 13	Rebate received	REBATE,
// 14	Gift code received	GIFT CODE,
// 15	Referral bonus RECEIVED!	REFERRAL,
// 16	New promotions	NEWS,
// 17	System maintenance	SYSTEM NOTICE,
// 18	Booster Level Unlocked!	PROMOTION,

// {
//   "_id": {"$oid": "67022fb760c13ea1ad2059e9"},
//   "branch": "bc119193",
//   "content": "New User information has been added",
//   "created_time": "2024-10-06T06:35:34.066Z",
//   "data_detail": {
//     "id": 751,
//     "userId": 751,
//     "type": "USER",
//     "status": "ACTIVE",
//     "action": null,
//     "method": null,
//     "created_time": "2024-10-06T06:35:34.066Z"
//   },
//   "read_status": false,
//   "request_id": "user_bc119193_751",
//   "status": "ACTIVE",
//   "title": "user.bc119193",
//   "type": "NEW_USER",
//   "user_id": 751
// }

const notiType = {
  REGISTER: 'NEW_USER',
  ID_VERIFICATION: 'ID_VERIFICATION',
  PHONE_VERIFICATION: 'VERIFIED_PHONE',
  VERIFIED_EMAIL: 'VERIFIED_EMAIL',
  LINE_VERIFICATION: 'LINE_VERIFICATION',
  DEPOSIT: 'DEPOSIT',
  WITHDRAW: 'WITHDRAW',
  CASHBACK: 'CASHBACK',
  BONUS: 'BONUS',
  REBATE: 'COMMISSION',
  GIFT_CODE: 'GIFT_CODE',
  REFERRAL: 'REFERRAL',
  NEWS: 'NEWS',
  SYSTEM_NOTICE: 'SYSTEM_NOTICE',
  PROMOTION: 'PROMOTION',
};

// noti_deposit_success_title;
// noti_deposit_success_content;
// noti_deposit_fail_title;
// noti_deposit_fail_content;
// noti_withdraw_success_title;
// noti_withdraw_success_content;
// noti_withdraw_fail_title;
// noti_withdraw_fail_content;

const notificationMapping: any = {
  [notiType.REGISTER]: {
    title: 'noti_register_title',
    content: 'noti_register_content',
    link: '/account/info',
  },
  [notiType.DEPOSIT]: {
    FINISHED: {
      title: 'noti_deposit_success_title',
      content: 'noti_deposit_success_content',
      link: '/account/history-payment',
    },
    CANCEL: {
      title: 'noti_deposit_fail_title',
      content: 'noti_deposit_fail_content',
      link: '/account/history-payment',
    },
  },
  [notiType.WITHDRAW]: {
    FINISHED: {
      title: 'noti_withdraw_success_title',
      content: 'noti_withdraw_success_content',
      link: '/account/history-payment',
    },
    CANCEL: {
      title: 'noti_withdraw_fail_title',
      content: 'noti_withdraw_fail_content',
      link: '/account/history-payment',
    },
  },
  [notiType.ID_VERIFICATION]: { title: '', content: '' },
  [notiType.PHONE_VERIFICATION]: {
    ACTIVE: {
      title: 'noti_phone_verification_title',
      content: 'noti_phone_verification_content',
      link: '/account/info',
    },
  },
  [notiType.VERIFIED_EMAIL]: {
    ACTIVE: {
      title: 'noti_phone_verification_title',
      content: 'noti_phone_verification_content',
      link: '/account/info',
    },
  },
  [notiType.LINE_VERIFICATION]: {
    title: 'noti_line_verification_title',
    content: 'noti_line_verification_content',
    // link: '/',
  },
  [notiType.CASHBACK]: {
    title: 'noti_cashback_title',
    content: 'noti_cashback_content',
    // link: '/',
  },
  [notiType.BONUS]: {
    title: 'noti_bonus_title',
    content: 'noti_bonus_content',
    link: '/account/promotion',
  },
  [notiType.REBATE]: {
    title: 'noti_rebate_title',
    content: 'noti_rebate_content',
    link: '/account/promotion',
  },
  [notiType.GIFT_CODE]: {
    title: 'noti_gift_code_title',
    content: 'noti_gift_code_content',
    link: '/account/promotion',
  },
  [notiType.REFERRAL]: {
    title: 'noti_referral_title',
    content: 'noti_referral_content',
    link: '/account/referral',
  },
  [notiType.NEWS]: {
    title: 'noti_news_title',
    content: 'noti_news_content',
    // link: '/',
  },
  [notiType.SYSTEM_NOTICE]: {
    title: 'noti_system_notice_title',
    content: 'noti_system_notice_content',
    // link: '/',
  },
  [notiType.PROMOTION]: {
    title: 'noti_promotion_title',
    content: 'noti_promotion_content',
    link: '/account/promotion',
  },
};

const useRenderNotiUser = () => {
  const { t } = useClientTranslation('Notification');
  const { router } = useAppRouter();
  const token = getCookie('token');
  const [loadings, setLoadings] = useState<string[]>([]);
  const { data, mutate } = useSWR(token ? '/notification' : null, () =>
    getNotification()
  );

  const notifiData = data?.data;

  const notifiDataFilter = notifiData?.filter((item: any) => {
    let notiItem =
      notificationMapping[item?.type] ||
      notificationMapping[item?.data_detail?.type];
    const detailStatus = item?.data_detail?.status;
    // const action = item?.data_detail?.action;

    if (notificationMapping?.[item?.type]?.[detailStatus]) {
      notiItem = notificationMapping?.[item?.type]?.[detailStatus];
    }
    return notiItem?.title && notiItem?.content;
  });

  const hasNotiUnRead =
    notifiDataFilter?.length > 0 &&
    notifiDataFilter?.some((item: any) => !item?.read_status);

  const notiCount = notifiDataFilter?.filter(
    (item: any) => !item?.read_status
  )?.length;

  if (!token) return null;

  const handleReadAll = async () => {
    if (!hasNotiUnRead) return;

    await Promise.all(
      notifiData.map((_: any) => {
        putNotification(_._id);
      })
    );
    await mutate();
  };

  const handleMarkNoti = async (item: any) => {
    try {
      setLoadings((prev: any) => {
        return [...prev, item?._id];
      });
      const markNotiResp = await putNotification(item?._id);
      if (markNotiResp.code === HttpStatusCode.Ok) {
        mutate();
        setLoadings((prev) => {
          return prev.filter((_) => _ === item?.id);
        });
      } else {
        setTimeout(() => {
          setLoadings((prev) => {
            return prev.filter((_) => _ === item?.id);
          });
        }, 500);
      }
      return markNotiResp.code === HttpStatusCode.Ok;
    } catch (error) {
      console.error('Failure by mark noti:', error);
    }
  };

  const renderIcon = (props = {}) => {
    return (
      <div className='relative md:mr-4'>
        <svg
          {...props}
          className='vw:mx-2 vw:w-6 vw:h-6 !mr-0 md:m-0 md:h-8 md:w-8'
          xmlns='http://www.w3.org/2000/svg'
          width='24'
          height='24'
          viewBox='0 0 24 24'
          fill='none'
        >
          <path
            fill-rule='evenodd'
            clip-rule='evenodd'
            d='M2 16.5959C2 16.2151 2.15471 15.8506 2.42864 15.586L3.45759 14.5922C3.84928 14.2139 4.06977 13.6922 4.06814 13.1476L4.05867 9.9946C4.04543 5.58319 7.61789 2 12.0293 2C16.4314 2 20 5.56859 20 9.97067L20 13.1716C20 13.702 20.2107 14.2107 20.5858 14.5858L21.5858 15.5858C21.851 15.851 22 16.2107 22 16.5858C22 17.3668 21.3668 18 20.5858 18H16C16 20.2091 14.2091 22 12 22C9.79086 22 8 20.2091 8 18H3.40408C2.62863 18 2 17.3714 2 16.5959ZM10 18C10 19.1046 10.8954 20 12 20C13.1046 20 14 19.1046 14 18H10ZM18 13.1716C18 14.2324 18.4214 15.2499 19.1716 16L4.87851 16C5.64222 15.246 6.07136 14.2161 6.06813 13.1416L6.05867 9.9886C6.04875 6.6841 8.7248 4 12.0293 4C15.3268 4 18 6.67316 18 9.97067L18 13.1716Z'
            fill='#845D00'
          />
        </svg>
        {hasNotiUnRead && (
          <>
            <div className='bg-error-base vw:w-2 vw:h-2 vw:border-1 md:border-1 absolute bottom-0 right-0 flex items-center justify-center rounded-full border border-white md:-bottom-1 md:-right-2 md:h-[16px] md:w-fit md:min-w-[20px]'>
              <div className='text-12 hidden text-white md:block md:p-1'>
                {notiCount}
              </div>
            </div>
          </>
        )}
      </div>
    );
  };

  const renderNotification = (callback?: (arg: any) => void) => {
    if (!notifiDataFilter?.length)
      // return <Empty description='Bạn chưa có thông báo!'></Empty>;
      return <Empty description={t('noti_empty')}></Empty>;

    return notifiDataFilter?.map((item: any) => {
      const seen = item?.read_status;

      let notiItem =
        notificationMapping[item?.type] ||
        notificationMapping[item?.data_detail?.type];
      const detailStatus = item?.data_detail?.status;
      // const action = item?.data_detail?.action;

      if (notificationMapping?.[item?.type]?.[detailStatus]) {
        notiItem = notificationMapping?.[item?.type]?.[detailStatus];
      }
      // if (action && detailStatus) {
      //   //case deposit/withdraw
      //   notiItem = notificationMapping?.[action]?.[detailStatus];
      // }
      if (!notiItem?.title && !notiItem?.content) return <></>;

      return (
        <div
          onClick={async () => {
            if (seen) return;
            const success = await handleMarkNoti(item);
            if (notiItem.link) {
              router.push(notiItem.link);
            }
            typeof callback === 'function' &&
              callback({
                success,
                hasLink: notiItem.link,
              });
          }}
          key={item?._id}
          className={clsx(
            'text-neutral-07 vw:text-14 md:text-14 vw:mb-3 vw:p-2 relative rounded-xl bg-[#fff] md:mb-3 md:p-2',
            seen
              ? 'shadow-6'
              : 'shadow-8 cursor-hand bg-[#f4f4f4] hover:bg-[#e9e9e9] active:!bg-[#e9e9e9]',
            loadings?.includes(item?._id) && '!bg-[#e9e9e9]'
          )}
        >
          <div className=' font-bold'>{t(notiItem?.title)}</div>
          <div className='my-1'>
            {t(notiItem?.content, {
              amount: Math.round(item?.data_detail?.amount),
            })}
          </div>
          {!seen && (
            <div className='bg-error-base vw:w-2 vw:h-2 vw:border-1 md:border-1 absolute right-3 top-4 rounded-full border border-white md:h-2 md:w-2' />
          )}
          {item?.created_time && (
            <div className='vw:text-12 md:text-12 text-[#9A9FA5]'>
              {dayjs(item?.created_time).format('hh:mm A')}
            </div>
          )}
        </div>
      );
    });
  };

  return {
    renderIcon,
    renderNotification,
    hasNotiUnRead,
    notiCount: notiCount,
    handleReadAll,
  } as const;
};

export default useRenderNotiUser;

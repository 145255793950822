'use client';

import { Switch } from 'antd';
import clsx from 'clsx';
import { usePathname, useRouter } from 'next/navigation';
import React from 'react';

import useClientTranslation from '@/components/hooks/useClientTranslation';

const SwitchLanguage: React.FC<any> = () => {
  const { lng } = useClientTranslation('');
  const isEngLng = lng === 'en';
  const pathname = usePathname();

  const router = useRouter();

  return (
    <Switch
      className={clsx(
        isEngLng ? 'switch-eng-lng' : 'switch-th-lng',
        '!bg-main-secondary !text-14 !h-[32px] w-[81px] !text-white'
      )}
      checkedChildren={
        <div className='flex min-h-[32px] items-center font-bold'>
          ENG
          <img
            className='ml-[14px] min-w-[32px]'
            src='/img/header/eng.svg'
            alt=''
          />
        </div>
      }
      unCheckedChildren={
        <div className='mt-[-32px] flex min-h-[32px] items-center font-bold'>
          <img
            className='-ml-[8px] mr-[8px] min-w-[32px]'
            src='/img/header/thai.svg'
            alt=''
          />
          THAI
        </div>
      }
      defaultChecked={isEngLng}
      onChange={() => {
        return;
      }}
      onClick={() => {
        setTimeout(() => {
          const pathnameWithSearchParams = window.location.search.slice(1)
            ? `${pathname}?${window.location.search.slice(1)}`
            : pathname;

          if (!isEngLng && !pathname.includes('/en'))
            router.replace('/en' + pathnameWithSearchParams);

          if (isEngLng && pathnameWithSearchParams.includes('/en')) {
            // /en/game/abc -> remove /en = empty -> /game/abc
            if (pathnameWithSearchParams.split('/')?.length > 2) {
              router.replace(
                pathnameWithSearchParams.replace('/en', '') || '/'
              );
            } else {
              // /en -> remove /en = / -> /
              router.replace(
                pathnameWithSearchParams.replace('/en', '/') || '/'
              );
            }
          }
        }, 280);
      }}
    />
  );
};

export default SwitchLanguage;
